.menu-list {
  width: 320px;
  margin-top: 26px;
}

.collapse-menu-item-icon {
  width: 18px;
  margin-right: 16px;
  padding-top: 2px;
  color: #183b56;
}

.menu-list-item {
  margin: 0 !important;
  padding: 0 !important;
}

.menu-list-link{
  color: #183b56 !important;
  font-weight: 400 !important;
  width: 100% !important;
  text-decoration: none !important;
}