.layout {
  display: flex;
  margin: 0;
  padding: 0;
}

.container {
  width: calc(100% - 240px);
}

.top-side-bar {
  font-size: 18px;
  font-weight: 600;
  background-color: #fff;
  padding: 16px;
  min-height: 26px;
  display: flex;
  justify-content: space-between;
}

.content-wrapper {
  display: block;
  background-color: #fff;
  color: #000;
  position: relative;
  min-height: 50vh;
  padding: 24px 16px;
  margin: 16px;
  margin-bottom: 80px;
}

.left-side-bar {
  overflow: hidden;
  width: 240px;
  border: none !important;
}

@media screen and (max-width: 768px) {
  .content-wrapper {
    display: block;
    margin: 0;
    margin-bottom: 80px;
    padding: 16px 0;
  }
  .top-side-bar {
    margin-bottom: 16px;
  }
  .container {
    width: 100%;
  }
}