body {
  margin: 0;
  padding: 0;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background-color: #f0f0f0;
}

body.in-iframe {
  background-color: transparent;
}

code {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif,
    monospace;
}

@media screen and (max-width: 767px) {
  input, select, textarea {
    font-size: 16px !important;
  }
}

/* Style for the main part of the vertical/horizontal scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Style for the background of the vertical track */
::-webkit-scrollbar-track:vertical {
  background-color: #EFEFEF;
  border-radius: 5px;
}

/* Style for the background of the horizontal track */
::-webkit-scrollbar-track:horizontal {
  background-color: #FFFFFF;
  border-radius: 5px;
}

/* Style for the vertical thumb */
::-webkit-scrollbar-thumb:vertical {
  background-color: #a9a7a7;
  border-radius: 5px;
}

/* Style for the horizontal thumb */
::-webkit-scrollbar-thumb:horizontal {
  background-color: #a9a7a7;
  border-radius: 5px;
}

/* Style for the vertical thumb on hover */
::-webkit-scrollbar-thumb:vertical:hover {
  background-color: #6d6c6c;
}

/* Style for the horizontal thumb on hover */
::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: #6d6c6c;
}
